<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <!-- <div style="width: 74%;display: flex;">
          <el-input
            class="hunt"
            v-model="input"
            placeholder="请输入会员姓名"
            size="large"
            @input="orangutan"
          />
          <el-input
            class="hunt"
            v-model="input"
            placeholder="请输入会员编码"
            size="large"
            @input="orangutan"
          />
          <el-select
            class="hunt"
            @focus="closeIt"
            v-model="ability"
            placeholder="请选择工会名称"
            size="large"
            @click="multiple"
            clearable
            @change="numerical"
            ref="selectIt"
          />
          <el-select class="hunt"  v-model="values" placeholder="是否设置" @change="numerical" clearable @clear="empty" size="large">
            <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search"
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div> -->
        <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div>
      </div>
      <!-- <div class="summation">
        <div class="hint">统计 : {{totality}}条</div>
          <div class="setting">
           
            <div>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="large"
                @click="append"
                ><el-icon class="cancelgu"> <Setting /> </el-icon>下载模板</el-button
              >
            </div>
           
            <div>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="large"
                @click="payment"
                ><img src="../../assets/daoru.png" style="width:22px;margin-right: 6px" alt="">批量导入</el-button
              >
            </div>
           
            <div>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="large"
                @click="channel"
                ><img src="../../assets/daochu.png" style="width:14px;margin-right: 6px" alt="">导入失败日志</el-button
              >
            </div>
           
            <div>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="large"
                @click="append"
                ><img src="../../assets/daochu.png" style="width:14px;margin-right: 6px" alt="">导出</el-button
              >
            </div>
        </div>
      </div> -->
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
        @row-click="unit"
      >
        <!-- 工会名称 -->
        <el-table-column
          label="工会名称"
          prop="unionName"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 创建人 -->
        <el-table-column
          label="创建人"
          prop="adminName"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 创建人 -->
        <!-- <el-table-column
          label="创建人"
          prop="adminName"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column> -->
        <!-- 状态 -->
        <el-table-column
          label="状态"
          prop="state"
          align="center"
          :formatter="condition"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 微信商户号 -->
        <!-- <el-table-column align="center" label="微信商户号" :show-overflow-tooltip='true' prop="mchId" /> -->
        <!-- 商户支付KEY -->
        <!-- <el-table-column align="center" label="商户支付KEY" :show-overflow-tooltip='true' prop="mchKey" /> -->
        <!-- 创建时间 -->
        <!-- <el-table-column align="center" label="创建时间" :show-overflow-tooltip='true' prop="createTime" :formatter="tiems"/> -->
        <!-- 备注 -->
        <!-- <el-table-column align="center" label="备注" :show-overflow-tooltip='true' prop="remarks" /> -->
        <!-- 缴费金额 -->
        <!-- <el-table-column align="center" label="应缴金额" :show-overflow-tooltip='true' prop="isEnable" /> -->
        <!-- 编辑 -->
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
              size="default"
              @click.stop="copyreader(scope.row.childCustodyUnionId)"
              class="bulur"
              ><el-icon class="cancel"> <EditPen /> </el-icon>编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <!-- <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]" 
        :page-size="pagesize"
        :total="totality"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      /> -->
    </el-card>
    <!-- 所属组织弹窗 -->
    <!-- <div>
      <s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div> -->
  </div>
</template>
<script>
import { Search,Setting,Plus,EditPen} from "@element-plus/icons-vue";
import { useRouter,useRoute  } from "vue-router";
import{ ref,onMounted,onActivated } from 'vue'
import { ZNexterior,Zzlist } from '../../utils/api'
import moments from 'moment'
import qs from 'qs'
// import { ElMessage } from "element-plus";
export default {
  name:"ZNexterior",
  setup() {
    const route = useRoute();
    // 发布状态
    let condition=(row, colum,cellValue)=>{
       if (cellValue == 1) {
        return "已发布";
      } else if (cellValue === 2) {
        return "未发布";
      }
    }
    // 导入日志跳转
    // let channel=()=>{
    //   router.push('/HFguide')
    // }
    // const form = reactive({
    //   name:"",
    //   breviary:"",
    // })
    // 设置缴费跳转
    // let payment=()=>{
    //   router.push('/HFset')
    // }
    // 设置提示按钮弹窗
    // let standard =ref(false)
    // 点击每一行跳转
    // let unit=(row)=>{
    //   console.log(row)
    // }
    // let selectIt=ref()
    // 下拉隐藏
    // let closeIt=()=>{
    //   selectIt.value.blur();
    // }
    // 删除弹窗
    // let remove=ref(false)
    // 会员姓名输入框改变
    // const orangutan=()=>{
    //   if(input.value==''&&ability.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // 选择组织名称变化
    // let numerical=()=>{
    //   if(input.value==''&&ability.value==''&&values.value==''){
    //     list()
    //   }
    //   Hcode.value=''
    // }
    // 搜索按钮
    // let search=()=>{
    //   console.log(input.value)
    //   console.log(Hcode.value)
    //   console.log(values.value)
    //   let data={
    //     limit:branches.value,
    //     page:currentPage.value,
    //     isEnable:values.value,
    //     title:input.value,
    //     unionCode:Hcode.value
    //   }
    //   ZNfigure(qs.stringify(data)).then((res)=>{
    //     // 列表总数
    //     totality.value=res.data.data.page.totalCount
    //     // 列表数据
    //     filterTableData.value=res.data.data.page.list
    //     if(filterTableData.value.length==0){
    //         texts.value='暂无数据'
    //       }
    //     console.log(res)
    //   })
    // }
    // 是否启用
    let values=ref('')
    // 标题内容
    let input=ref('')
    // 搜索框弹窗
    // 获取code
    let Hcode=ref('')
    // 获取选项
    let ability=ref('')
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    let visible=ref(false)
    let multiple=()=>{
      visible.value=true
    }
    // 搜索框
    let texture=ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    // 获取所有组织列表
    // let own=()=>{
    //   Zzlist().then((res)=>{
    //     console.log(res)
    //     if(res.data.code==0){
    //       texture.value=res.data.data.wholeUnionList
    //     }
    //   })
    // }
    // 加载中 暂无数据列表
    let texts=ref('加载中')
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.UserId
      if(translate.value==1){
        texts.value='加载中'
        // branches.value=6
        // currentPage.value=1
        list()
      }
    })
    onMounted(()=>{
      list()
      // 所有组织列表接口
      // own()
    })
     // 时间转换
    let tiems=(row, colum,cellValue )=>{
    if(typeof cellValue=='number'){
      return moments(cellValue*1000).format("YYYY-MM-DD")
    }
    }
    // 分页处理
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      list()
      // if(input.value!==''|| ability.value!==''||values.value!==''){
      //   search()
      // }else{
      //   list()
      // }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      list()
      // if(input.value!==''|| ability.value!==''||values.value!==''){
      //   search()
      // }else{
      //   list()
      // }
    }
    // 数据增加
    let branches=ref(6)
    // 数据总数
    let totality =ref(null)
    // 获取人物列表
    let list =()=>{
      let data={
        // limit:branches.value,
        // page:currentPage.value,
        childCustodyId:sessionStorage.getItem('ZNexterior')
      }
      ZNexterior(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          // 列表总数
          // totality.value=res.data.data.childCustodyClassList.totalCount
          // 列表数据
          filterTableData.value=res.data.data.childCustodyUnionList
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 编辑跳转
    const copyreader = (id)=>{  
      // console.log(id)
      sessionStorage.setItem('ZNWBdit', id)
      router.push({ name: 'ZNWBdit', params: { UserId: 1 }});
      // router.push('/ZNBJedit')
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      router.push('/ZNWBadd')
    }
    // 列表数据循环
    const filterTableData = ref([])
    return {
      condition,
      // 导入日志跳转
      // channel,
      // payment,
      // 设置提示弹窗
      // form,
      // standard,
      // 是否启用
      values,
      // unit,
      // selectIt,
      // closeIt,
      // numerical,
      // orangutan,
      // 标题内容
      input,
      // 搜索按钮
      // search,
      // 搜索框
      ability,
      dendrogram,
      visible,
      multiple,
      defaultProps,
      texture,
      // 分页处理
      handleCurrentChange,
      handleSizeChange,
      currentPage,
      pagesize,
      // 加载中 暂无数据
      texts,
      tiems,
      // 列表总数
      totality,
      // 编辑跳转
      copyreader,
      // 头部组织添加跳转
      append,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    Search,
    Setting,
    Plus,
    EditPen
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form{
  display: flex;
      flex-direction: column;
    align-items: flex-end;
}
.setting{
  display: flex;
}
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
.summation{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 150px);
  overflow: auto;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
